import { useTranslation } from 'hooks/useContentful';
import { useSEOLocaleRoutes } from 'hooks/useSEOLocaleRoutes';
import { NextSeo } from 'next-seo';

const seoDescription = 'Create and save routes to shipping and trucking facilities';
const seoTitle = 'Truck Route Planner';
const relativePath = '/map';

export const RoutePlannerPageSEO = () => {
  const { t } = useTranslation();
  const { languageAlternates, nextSEOCanonicalUrl } = useSEOLocaleRoutes(relativePath);

  return (
    <NextSeo
      title={t('ROUTE_PLANNER_SEO_TITLE') ?? seoTitle}
      description={t('ROUTE_PLANNER_SEO_DESCRIPTION') ?? seoDescription}
      languageAlternates={languageAlternates}
      canonical={nextSEOCanonicalUrl}
    />
  );
};

RoutePlannerPageSEO.displayName = 'RoutePlannerPageSEO';
