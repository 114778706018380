import { ClientSideOnly } from 'components/common/ClientSideOnly';
import { MapControllerProvider } from 'components/RoutePlanner/RouteBuilder/MapControllerContext';
import { RouteBuilderHandlersProvider } from 'components/RoutePlanner/RouteBuilder/RouteBuilderHandlersContext';
import { WaypointsProvider } from 'components/RoutePlanner/RouteBuilder/WaypointsContext';
import { RoutePlanner } from 'components/RoutePlanner/RoutePlanner';
import { MapControllers } from 'models/MapControllers';
import { memo } from 'react';

export const RoutePlannerPageContent = memo(() => {
  return (
    <main>
      <ClientSideOnly>
        <MapControllerProvider mapController={MapControllers.ROUTE_PLANNER}>
          <WaypointsProvider>
            <RouteBuilderHandlersProvider>
              <RoutePlanner />
            </RouteBuilderHandlersProvider>
          </WaypointsProvider>
        </MapControllerProvider>
      </ClientSideOnly>
    </main>
  );
});

RoutePlannerPageContent.displayName = 'RoutePlannerPageContent';
