'use client';
import { ClientSideOnly } from 'components/common/ClientSideOnly';
import React, { Suspense } from 'react';

const LazyDeviceSecretComponent = React.lazy(() => import('./DeviceSecretComponent'));

export const ClientSideDeviceSecret = React.memo(() => {
  return (
    <ClientSideOnly>
      <Suspense>
        <LazyDeviceSecretComponent />
      </Suspense>
    </ClientSideOnly>
  );
});

ClientSideDeviceSecret.displayName = 'ClientSideDeviceSecret';
