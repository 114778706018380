import { AppHead } from 'components/AppHead';
import { ClientSideDeviceSecret } from 'components/DeviceSecret/ClientSideDeviceSecret';
import { PublicNavigationDynamic } from 'components/PublicNavigation/PublicNavigationDynamic';
import { RoutePlannerPageContent } from 'components/RoutePlanner/RoutePlannerPage/RoutePlannerPageContent';
import { RoutePlannerPageSEO } from 'components/RoutePlanner/RoutePlannerPage/RoutePlannerPageSEO';
import { ContentfulContext } from 'hooks/useContentful/useContentful';
import { useHideDriftWidget } from 'hooks/useDriftWidget';
import { TruckOptionsContext } from 'hooks/useTruckOptions/useTruckOptions';
import { RoutePlannerPageProps } from 'types/pages/RoutePlannerTypes';

export const RoutePlannerPage = ({ contentfulQuery, truckOptions }: RoutePlannerPageProps) => {
  useHideDriftWidget();

  return (
    <div className="w-screen h-screen overflow-hidden">
      <AppHead />
      <ContentfulContext.Provider value={{ contentfulQuery }}>
        <TruckOptionsContext.Provider value={truckOptions}>
          <RoutePlannerPageSEO />
          <PublicNavigationDynamic />
          <RoutePlannerPageContent />
        </TruckOptionsContext.Provider>
      </ContentfulContext.Provider>
      <ClientSideDeviceSecret />
    </div>
  );
};

RoutePlannerPage.displayName = 'RoutePlannerPage';
