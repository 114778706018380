import dynamic from 'next/dynamic';
import { truckMapConfig } from 'truckMapConfig';

export const PublicNavigationDynamic = dynamic(
  () =>
    !truckMapConfig.isTenstreet
      ? import('components/PublicNavigation').then((mod) => mod.PublicNavigation)
      : Promise.resolve(() => null),
  { ssr: false }
);
