import { setClientDeviceSecret } from '@truckmap/common';
import { RoutePlannerPage } from 'components/RoutePlanner/RoutePlannerPage/RoutePlannerPage';
import { getCookieFromPage } from 'lib/auth/getDeviceSecretFromPage';
import { loadContentfulTranslationData } from 'lib/contentful/loadContentfulData';
import { fetchTruckOptions } from 'lib/fetchTruckOptions';
import { normalizeLocale } from 'lib/normalizeLocale';
import { GetServerSideProps, NextPage } from 'next';
import { truckMapConfig } from 'truckMapConfig';
import { RoutePlannerPageProps } from 'types/pages/RoutePlannerTypes';

const PublicRoutePlanner: NextPage<RoutePlannerPageProps> = RoutePlannerPage;

export const getServerSideProps: GetServerSideProps<RoutePlannerPageProps> = async (context) => {
  const { locale = truckMapConfig.i18n.defaultLocale, res } = context;
  const deviceSecret = getCookieFromPage(context, truckMapConfig.cookies.deviceSecret);
  res.setHeader('Cache-Control', 's-maxage=20, stale-while-revalidate=60');

  try {
    const { contentfulQuery } = await loadContentfulTranslationData(normalizeLocale(locale));

    const truckOptions = await fetchTruckOptions();
    setClientDeviceSecret(deviceSecret);

    return {
      props: {
        truckOptions,
        contentfulQuery
      }
    };
  } catch (error) {
    console.error(error.response.data);
    return {
      notFound: true
    };
  }
};

export default PublicRoutePlanner;
